import { UPDATE_CREA } from "../actions/types";
import { getNextMonth } from "../utils/getNextMonth";

const initialState = {
  data_inizio: getNextMonth()[0],
  data_fine: getNextMonth()[1],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case UPDATE_CREA:
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };
    default:
      return state;
  }
}
