import React, { useEffect, lazy } from "react";
import store from "./store";
import { Provider } from "react-redux";
import { Router, Route, Switch } from "react-router-dom";
import { createBrowserHistory } from "history";
import { loadUser } from "./DashboardCoordinatore/actions/auth";
import PrivateRoute from "./DashboardCoordinatore/layout/PrivateRoute";
import ModalsListener from "./DashboardCoordinatore/components/ModalsListener/ModalsListener";
import ScrollToTop from "./DashboardCoordinatore/components/ScrollToTop/ScrollToTop";
import Loading from "./DashboardCoordinatore/layout/Loading";
import "./App.css";
import { LicenseManager } from "ag-grid-enterprise";
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";

LicenseManager.setLicenseKey(
  "CompanyName=GDP ANALYTICS SRL,LicensedGroup=GDP Analytics DevTeam,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=6,AssetReference=AG-014593,ExpiryDate=26_March_2022_[v2]_MTY0ODI1MjgwMDAwMA==0d9e6cfe1142113ddca4383abf1d51de"
);

// create history
const hist = createBrowserHistory();

// lazy load all routes in the application
const Login = lazy(() => import("./Website/routes/Login/Login"));
const DashboardCoordinatore = lazy(() =>
  import("./DashboardCoordinatore/layout")
);
const DashboardOperatore = lazy(() => import("./DashboardOperatore/layout"));
const RecuperoPassword = lazy(() =>
  import("./Website/routes/RecuperoPassword/RecuperoPassword")
);

export const App = () => {
  var urlInitial = window.location.href;
  useEffect(() => {
    store.dispatch(loadUser());
    // document.title has been moved in index.js in layout
  }, []);
  return (
    <Provider store={store}>
      <Router history={hist}>
        <div className="App">
          <ModalsListener></ModalsListener>
          <ScrollToTop />
          <Switch>
            <React.Suspense fallback={<Loading></Loading>}>
              <Route
                path="/"
                exact
                render={(props) => <Login urlInitial={urlInitial}></Login>}
              ></Route>
              <PrivateRoute
                path="/dashboard/coordinatore"
                component={DashboardCoordinatore}
              ></PrivateRoute>
              <PrivateRoute
                path="/dashboard/operatore"
                component={DashboardOperatore}
              ></PrivateRoute>
              <Route
                path="/password_reset"
                component={RecuperoPassword}
              ></Route>
            </React.Suspense>
          </Switch>
        </div>
      </Router>
    </Provider>
  );
};

export default App;
