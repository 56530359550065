export const OPEN_SIDEBAR = "OPEN_SIDEBAR";
export const OPEN_SIDEBAR_WITHOUT_CLICK = "OPEN_SIDEBAR_WITHOUT_CLICK";
export const CLOSE_SIDEBAR = "CLOSE_SIDEBAR";
export const HIDE_SIDEBAR = "HIDE_SIDEBAR";
export const UPDATE_LAYOUT = "UPDATE_LAYOUT";
export const AUTH_ERROR = "AUTH_ERROR";
export const USER_LOADED = "USER_LOADED";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const GET_ERRORS = "GET_ERRORS";
export const IS_LOADING = "IS_LOADING";
export const GET_COMPETENZE = "GET_COMPETENZE";
export const GET_TURNI = "GET_TURNI";
export const UPDATE_CREA = "UPDATE_CREA";
export const UPDATE_PUBBLICATI = "UPDATE_PUBBLICATI";
export const UPDATE_TURNISTICA = "UPDATE_TURNISTICA";
export const GET_TURNI_REPERIBILITA = "GET_TURNI_REPERIBILITA";
export const GET_ASSOCIAZIONE_SALE = "GET_ASSOCIAZIONE_SALE";

// export const API_URL = "http://piattaforma-hturn.gdpanalytics.local/api"; // locale
// export const API_URL = "http://10.10.0.9/api"; // se il primo non va
export const API_URL = "https://demo.hturn.cloud/api"; // produzione
// export const API_URL = "https://asl2-abruzzo.hturn.cloud/api"; // asl2-abruzzo
