import axios from "axios";
import {
  USER_LOADED,
  AUTH_ERROR,
  LOGOUT_SUCCESS,
  LOGIN_FAIL,
  API_URL,
  GET_ERRORS,
  IS_LOADING,
  REGISTER_SUCCESS,
} from "./types";
import { tokenConfig } from "../utils/tokenConfig";
import SuccessAlert from "../components/Modals/SuccessAlert";
import { getErrors } from "../utils/getErrors";

// CHECK TOKEN & LOAD USER
export const loadUser = () => async (dispatch) => {
  try {
    // check if the token is valid and also user info
    const res = await axios.get(
      `${API_URL}/authentication/user/`,
      tokenConfig()
    );
    dispatch({
      type: USER_LOADED,
      payload: res.data,
    });
  } catch {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const login = (username, password) => async (dispatch) => {
  // Headers
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  // Request Body
  const body = JSON.stringify({ username, password });
  try {
    const res = await axios.post(
      `${API_URL}/authentication/login/`,
      body,
      config
    );
    localStorage.setItem("token", res.data.token);
    dispatch(loadUser());
  } catch (err) {
    console.log(err);
    dispatch({
      type: LOGIN_FAIL,
    });
    getErrors(err, dispatch);
  }
};

// LOGOUT
export const logout =
  (reload = true) =>
  async (dispatch) => {
    try {
      await axios.post(
        `${API_URL}/authentication/logout/`,
        null,
        tokenConfig()
      );
      dispatch({
        type: LOGOUT_SUCCESS,
      });
      if (reload === true) {
        window.location.reload();
      }
    } catch (err) {
      getErrors(err, dispatch);
    }
  };

// UPDATE INFO ACCOUNT
export const updateInfoAccount = (form) => async (dispatch) => {
  try {
    dispatch({
      type: IS_LOADING,
    });
    const res = await axios({
      method: "put",
      url: `${API_URL}/authentication/user/`,
      data: form,
      ...tokenConfig(),
    });
    dispatch({
      type: USER_LOADED,
      payload: res.data,
    });
    dispatch({
      type: IS_LOADING,
    });
    SuccessAlert("Done!");
  } catch (err) {
    dispatch({
      type: IS_LOADING,
    });
    getErrors(err, dispatch);
  }
};

export const passwordResetRequest = (email) => async (dispatch) => {
  try {
    dispatch({
      type: IS_LOADING,
    });
    const res = await axios.post(`${API_URL}/password_reset/`, {
      email: email,
    });
    dispatch({
      type: IS_LOADING,
    });
    SuccessAlert(
      "Un link è stato inviato alla tua mail per recuperare la password"
    );
  } catch (err) {
    dispatch({
      type: IS_LOADING,
    });
    getErrors(err, dispatch);
  }
};

export const passwordReset = (password, token) => async (dispatch) => {
  try {
    dispatch({
      type: IS_LOADING,
    });
    const res = await axios.post(`${API_URL}/password_reset/confirm/`, {
      password: password,
      token: token,
    });
    dispatch({
      type: REGISTER_SUCCESS,
    });
    dispatch({
      type: IS_LOADING,
    });
    SuccessAlert("La password è stata aggiornata con successo");
    // dispatch(logout(false));
  } catch (err) {
    dispatch({
      type: IS_LOADING,
    });
    getErrors(err, dispatch);
  }
};
