import React from "react";
import Logo from "../../DashboardCoordinatore/images/logo_ht.png";

function Loading(props) {
  return (
    <div
      style={{
        width: "100%",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: 15,
          }}
        >
          <img
            className="rotateImg"
            src={Logo}
            style={{ width: 40, height: 40 }}
          ></img>
        </div>
        <i style={{ textAlign: "center" }}>Caricamento, attendere...</i>
      </div>
    </div>
  );
}

export default Loading;
