import { combineReducers } from "redux";
import auth from "./auth";
import layout from "./layout";
import errors from "./errors";
import isLoading from "./isLoading";
import competenze from "./competenze";
import turni from "./turni";
import crea from "./crea";
import turnistica from "./turnistica";
import reperibilita from "./reperibilita";
import pubblicati from "./pubblicati";

export default combineReducers({
  auth,
  layout,
  errors,
  isLoading,
  competenze,
  turni,
  crea,
  turnistica,
  reperibilita,
  pubblicati,
});
