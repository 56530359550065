import { GET_ERRORS } from "../actions/types";

export const getErrors = (err, dispatch) => {
  var errors = {
    msg: { non_field_errors: "C'è stato un errore" },
    status: 400,
  };
  if (err.response !== undefined) {
    errors = {
      msg: err.response.data,
      status: err.response.status,
    };
  }
  if (
    err.response !== undefined &&
    err.response.data !== undefined &&
    err.response.data.detail !== undefined &&
    err.response.data.detail === "Invalid token."
  ) {
    window.location.reload();
  } else {
    dispatch({
      type: GET_ERRORS,
      payload: errors,
    });
  }
};
